import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import HeroSlider from 'components/HeroSlider';
import Videos from 'blocks/Videos';
import url from 'helpers/url';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { useIntl, Link } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    SharedStyles,
    Container,
    ImageVideoTextGrid,
    ImageTextGrid,
    TabsSide,
} from '../kenra-storybook/index';

let Color = require('color');

const { StSectionInfo, StTitle, StLinkMore } = SharedStyles;

export default function CreativeLanding({ page }) {
    let showFaq = false;
    let viewAllUrl = url.colorList + '?filter=creatives';
    let intl = useIntl();

    return (
        <>
            <Spacing>
                <HeroSlider slides={page.slides} />
            </Spacing>

            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h2>{page.title}</h2>
                        </StTitle>
                        {documentToReactComponents(JSON.parse(page.text.raw || '{}'))}
                        <StLinkMore styleBig>
                            <Link to={viewAllUrl}>
                                {intl.formatMessage({ id: 'product-view-all' })}
                            </Link>
                        </StLinkMore>
                    </Container>
                </StSectionInfo>
            </Spacing>

            {page.newProductImages && (
                <Spacing removeSpaceTop>
                    <ImageTextGrid
                        bgImg={get(
                            page.productImageBackground,
                            'localFile.publicURL'
                        )}
                        items={page.newProductImages.fragments.map(
                            (imageData, index) => {
                                let returnData = {};
                                imageData.forEach(fragmentData => {
                                    switch (fragmentData.key) {
                                        case 'Image':
                                            returnData['img'] =
                                                fragmentData.value;
                                            break;
                                        case 'Title':
                                            returnData['title'] =
                                                fragmentData.value;
                                            break;
                                        case 'Text':
                                            returnData['text'] =
                                                fragmentData.value;
                                            break;
                                        case 'Url':
                                            returnData['url'] =
                                                fragmentData.value;
                                            break;
                                        default:
                                            break;
                                    }
                                });
                                return {
                                    img: returnData.img,
                                    title: returnData.title,
                                    text: returnData.text,
                                    linkText: intl.formatMessage({
                                        id: 'product-view-all',
                                    }),
                                    linkHref: returnData.url,
                                    linkStyleWhite: false,
                                };
                            }
                        )}
                    />
                </Spacing>
            )}

            {page.bottomImageVideo && (
                <Spacing removeSpaceTop>
                    <ImageVideoTextGrid
                        styleSquare
                        rowRevers
                        items={page.bottomImageVideo.map(
                            (imageVideo, index) => {
                                let bgColor = get(
                                    page,
                                    `bottomColors[${index}]`,
                                    '#d3d3d3'
                                );
                                if (imageVideo.video) {
                                    let file = get(
                                        imageVideo.video.file,
                                        'localFile.publicURL'
                                    );
                                    return {
                                        rowBg: bgColor,
                                        title: get(
                                            page,
                                            `bottomTitles[${index}]`
                                        ),
                                        text: get(
                                            page,
                                            `bottomTexts[${index}]`
                                        ),
                                        linkText: get(
                                            page,
                                            `bottomButtonTexts[${index}]`
                                        ),
                                        linkHref: get(
                                            page,
                                            `bottomButtonLinks[${index}]`
                                        ),
                                        linkStyleWhite: Color(bgColor).isDark(),
                                        rowColorWhite: Color(bgColor).isDark(),
                                        btnSizeMedium: true,
                                        //                  bgImg: getPreviewUrl(youTubeId),
                                        videoType: 'video/mp4',
                                        videoUrl: file,
                                        autoPlay: true,
                                        //                  videoOptions: [{ src: getEmbedUrl(youTubeId) }],
                                    };
                                } else {
                                    return {
                                        rowBg: bgColor,
                                        img: get(
                                            imageVideo.image,
                                            'localFile.publicURL'
                                        ),
                                        title: get(
                                            page,
                                            `bottomTitles[${index}]`
                                        ),
                                        text: get(
                                            page,
                                            `bottomTexts[${index}]`
                                        ),
                                        linkText: get(
                                            page,
                                            `bottomButtonTexts[${index}]`
                                        ),
                                        linkHref: get(
                                            page,
                                            `bottomButtonLinks[${index}]`
                                        ),
                                        linkStyleWhite: Color(bgColor).isDark(),
                                        rowColorWhite: Color(bgColor).isDark(),
                                    };
                                }
                            }
                        )}
                    />
                </Spacing>
            )}

            <Spacing>
                <Videos
                    title={intl.formatMessage({ id: 'kenra-education-videos' })}
                    videos={page.videos}
                    bgImage={get(page, 'videosImage.localFile.publicURL')}
                />
            </Spacing>

            {page.faqItems && showFaq && (
                <Spacing>
                    <Container useLargeWidth>
                        <TabsSide
                            items={page.faqItems.sections.map(section => ({
                                title: section.name,
                                accordionItems: Array.isArray(section.qa)
                                    ? section.qa.map(qa => ({
                                          title: qa.q,
                                          text: qa.a,
                                      }))
                                    : [],
                            }))}
                        />
                    </Container>
                </Spacing>
            )}
        </>
    );
}
