import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import CreativeLanding from 'components/CreativeLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function CreativeColorLandingPage(props) {
    let {
        data: { contentfulCreativeLanding },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCreativeLanding,
                    'seoListing.description.description'
                )}
                title={get(contentfulCreativeLanding, 'seoListing.seoTitle')}
                slug={'kenra-color'}
            />
            <CreativeLanding page={contentfulCreativeLanding} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCreativeLanding(node_locale: { eq: $locale }) {
            title
            text {
                raw
            }
            bottomTitles
            bottomTexts
            bottomImageVideo {
                image {
                    localFile {
                        publicURL
                    }
                }
            }
            bottomColors
            bottomButtonTexts
            bottomButtonLinks
            slides {
                ...SlideFields
            }
            videosImage {
                localFile {
                    publicURL
                }
            }
            videos {
                ...ContentfulVideoFields
            }
            productImageBackground {
                localFile {
                    publicURL
                }
            }
            faqItems {
                sections {
                    qa {
                        a
                        q
                    }
                    name
                }
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
            newProductImages {
                fragments {
                    key
                    value
                    type
                }
            }
        }
    }
`;

/*
// THIS IS NOT NEEDED AT THE MOMENT
      bottomImageVideo {
        image {
          localFile {
            publicURL
          }
        }
        video {
          ...ContentfulVideoFields
        }
      }

export const query = graphql`
  query {
    contentfulColorLanding {
      title
      text {
        raw
      }
      bottomTitles
      bottomTexts
      bottomImages {
        localFile {
          publicURL
        }
      }
      bottomColors
      bottomButtonTexts
      bottomButtonLinks
      slides {
        ...SlideFields
      }
      videosImage {
        localFile {
          publicURL
        }
      }
      videos {
        ...ContentfulVideoFields
      }
      stylistTools {
        ...ContentfulToolFields
      }
    }
  }
`;

*/
